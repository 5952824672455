<div class="tiptap-editor">
  <div #editorContainer class="editor-content"></div>
  <div class="toolbar" *ngIf="showToolbar">
    <ion-button fill="clear" class="btn-small" (click)="addLink()">
      <ion-icon name="link-outline" style="margin-right: 3px"></ion-icon>
    </ion-button>
  </div>
</div>

<div class="menu" style="visibility: hidden">
  <ion-button fill="clear" class="btn-small" (click)="addLink()" style="color: white">
    <ion-icon name="link-outline" style="margin-right: 3px"></ion-icon>
    Add link
  </ion-button>
  <ion-button fill="clear" class="btn-small" (click)="removeStyling()" style="color: white">
    <ion-icon name="close-outline" style="margin-right: 3px"></ion-icon>
    Remove formatting
  </ion-button>
</div>
