import { CommonModule } from '@angular/common';
import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonicModule, PopoverController } from '@ionic/angular';
import { Editor, Extension } from '@tiptap/core';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import Link from '@tiptap/extension-link';
import { Placeholder } from '@tiptap/extension-placeholder';
import StarterKit from '@tiptap/starter-kit';
import { Markdown } from 'tiptap-markdown';

// Import only the TextPopoverComponent
import { TextPopoverComponent } from '../text-popover/text-popover.component';

@Component({
  selector: 'app-tiptap-editor',
  templateUrl: './tiptap-editor.component.html',
  styleUrls: ['./tiptap-editor.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TiptapEditorComponent),
      multi: true,
    },
  ],
})
export class TiptapEditorComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @ViewChild('editorContainer') private editorContainer: ElementRef;

  @Input() placeholder: string = '';
  @Input() editable: boolean = true;
  @Input() enableLinkEditing: boolean = false;
  @Input() showToolbar: boolean = false;

  private editor: Editor;
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};
  private initialContent: string = '';

  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit() {
    // Don't initialize editor before DOM is ready
    return this.editor;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initEditor();
    });
  }

  private initEditor() {
    const extensions: Extension[] = [
      StarterKit,
      Markdown.configure({
        html: false,
        transformPastedText: true,
        transformCopiedText: true,
      }),
      Placeholder.configure({
        placeholder: this.placeholder,
      }),
    ];

    if (this.enableLinkEditing) {
      const linkEditingExtensions = [
        Link.configure({
          linkOnPaste: true,
          autolink: false,
          openOnClick: true,
          HTMLAttributes: {
            target: '_blank',
            rel: 'noopener noreferrer',
          },
        }),
        BubbleMenu.configure({
          element: document.querySelector('.menu'),
          tippyOptions: {
            hideOnClick: true,
            appendTo: document.body,
          },
        }),
      ];
      extensions.push(...linkEditingExtensions);
    }

    this.editor = new Editor({
      element: this.editorContainer?.nativeElement,

      editable: this.editable,
      content: this.initialContent || '',
      onUpdate: ({ editor }) => {
        const markdown = editor.storage.markdown.getMarkdown();
        this.onChange(markdown);
      },
      extensions,
      editorProps: {
        handleKeyDown: (view, event) => {
          // Mod+K for adding a link (Command+K on Mac, Ctrl+K on Windows/Linux)
          if ((event.metaKey || event.ctrlKey) && event.key === 'k' && this.enableLinkEditing) {
            event.preventDefault();
            this.addLink();
            return true;
          }
          return false;
        },
      },
    });
  }

  /**
   * Open a popover to add a link (activated by Mod+K keyboard shortcut or bubble menu)
   */
  async addLink() {
    if (!this.enableLinkEditing) return;

    const popover = await this.popoverCtrl.create({
      component: TextPopoverComponent,
      componentProps: {
        title: 'Add link',
        placeholder: 'https://',
        shouldSelect: true,
        showSaveButton: true,
        saveOnEnter: true,
        callback: async (href: string) => {
          if (href) {
            this.editor.commands.setLink({ href });
          }
        },
      },
      showBackdrop: true,
      event,
    });
    await popover.present();
  }

  /**
   * Remove all formatting from the selected text
   */
  async removeStyling() {
    this.editor.commands.unsetAllMarks();
  }

  ngOnDestroy() {
    this.editor?.destroy();
  }

  writeValue(value: string): void {
    if (!value) return;

    this.initialContent = value;

    if (this.editor) {
      this.editor.commands.setContent(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.editor) {
      this.editor.setEditable(!isDisabled);
    }
  }
}
